import { GroupDto, nonNullable } from "api-shared";

export const getFullGroupPath = (groups: GroupDto[], currentGroup: GroupDto | null, skipSelf?: boolean): GroupDto[] => {
    const path: GroupDto[] = [];
    if (currentGroup === null) {
        return path;
    }
    let current: number | null = currentGroup.id;
    const check = (group: GroupDto) => group.id === current;
    while (current !== null) {
        const group = groups.find(check);
        if (group) {
            if (!skipSelf || (skipSelf && current !== currentGroup.id)) {
                path.unshift(group);
            }
            current = group.parentGroupId;
        } else {
            break;
        }
    }
    return path;
};

export const getChildGroups = (groups: GroupDto[], currentGroup: GroupDto | null): GroupDto[] => {
    const allChildren = [];
    const childGroupIds = currentGroup?.childGroupIds ?? groups.filter((group) => group.parentGroupId === null).map((group) => group.id);
    const currentChildren = childGroupIds.map((g) => groups.find((group) => group.id === g)).filter(nonNullable);
    for (const child of currentChildren) {
        allChildren.push(...getChildGroups(groups, child), child);
    }
    return allChildren;
};
